/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Typography from '@common_typography';
import { modules } from '@config';
import { get as _get } from 'lodash';
import { setCookiesCustom } from '@helper_cookies';
import useStyles from '@core_modules/checkout/pages/default/components/delivery/style';
import useStylesRoot from '@core_modules/checkout/pages/default/components/style';
import classNames from 'classnames';
import Router from 'next/router';

const ShippingView = (props) => {
    const {
        checkout, handleSelect, t,
    } = props;
    const classes = useStyles();
    const styles = useStylesRoot();
    const checkStyles = (delivery) => ((checkout.selected.delivery === delivery)
        ? classNames(classes.item, classes.active, `${delivery}Delivery`)
        : classNames(classes.item, `${delivery}Delivery`));
    const items = _get(checkout, 'data.cart.items');
    let isShowDelivery = false;
    let isShowPickup = false;
    let hasZeroQtyPickup = false;
    let hasNonZeroQtyPickup = false;
    let hasZeroQtyDelivery = false;
    let hasNonZeroQtyDelivery = false;
    let isNotValidPickup = false;
    let isNotValidDelivery = false;
    if (items && items.length) {
        items.forEach((element) => {
            const qtyOrdered = element?.quantity ?? 0;
            let qtyStockPickup = element?.product?.stock_qty?.qty_pickup ?? 0;
            let qtyStockOnline = element?.product?.stock_qty?.qty_online ?? 0;
            if (element.product.type_id === 'configurable') {
                qtyStockPickup = element?.configured_variant?.stock_qty?.qty_pickup ?? 0;
                qtyStockOnline = element?.configured_variant?.stock_qty?.qty_online ?? 0;
            }
            isShowDelivery = qtyStockOnline >= qtyOrdered;
            isShowPickup = qtyStockPickup >= qtyOrdered;

            if (!isShowPickup) {
                hasZeroQtyPickup = true;
            }
            if (isShowPickup) {
                hasNonZeroQtyPickup = true;
            }

            if (!isShowDelivery) {
                hasZeroQtyDelivery = true;
            }
            if (isShowDelivery) {
                hasNonZeroQtyDelivery = true;
            }

            if (hasZeroQtyPickup && hasNonZeroQtyPickup) {
                isNotValidPickup = true;
            }

            if (hasZeroQtyDelivery && hasNonZeroQtyDelivery) {
                isNotValidDelivery = true;
            }
        });
    }

    React.useEffect(() => {
        if (isShowDelivery) {
            handleSelect('home');
        }
        if (!isShowDelivery && isShowPickup) {
            handleSelect('pickup');
        }
        if (!isShowDelivery && !isShowPickup) {
            setCookiesCustom('checkout_error_msg', 'Qty yang di input tidak tersedia');
            Router.push('checkout/cart');
        }
        if (isNotValidPickup && isNotValidDelivery) {
            setCookiesCustom('checkout_error_msg', 'Tidak dapat menggabungkan product delivery dan pickup');
            Router.push('checkout/cart');
        }
    }, [isShowDelivery, isShowPickup]);

    return (
        <div id="checkoutDeliveryMethod" className={styles.block} style={{ marginBottom: 40 }}>
            <Typography variant="h2" type="bold" letter="uppercase">
                {t('checkout:deliveryMethod:label')}
            </Typography>
            <div className="row">
                {isShowDelivery && (
                    <div className="col-xs-6">
                        <div className={checkStyles('home')} onClick={() => handleSelect('home')}>
                            <div className="column">
                                <Typography variant="span" type="bold">
                                    {t('checkout:deliveryMethod:homeDelivery')}
                                </Typography>
                                <Typography className="hidden-mobile">
                                    {t('checkout:deliveryMethod:homeDeliveryDesc')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}

                {modules.checkout.pickupStore.enabled && isShowPickup && !isNotValidPickup && (
                    <div className="col-xs-6">
                        <div className={checkStyles('pickup')} onClick={() => handleSelect('pickup')}>
                            <div className="column">
                                <Typography variant="span" type="bold">
                                    {/* {t('checkout:deliveryMethod:pickupDelivery')} */}
                                    Click and Collect
                                </Typography>
                                <Typography className="hidden-mobile">
                                    {t('checkout:deliveryMethod:pickupDeliveryDesc')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
                {modules.checkout.inStorePickup.enabled && (
                    <div className="col-xs-6">
                        <div className={checkStyles('instore')} onClick={() => handleSelect('instore')}>
                            <div className="column">
                                <Typography variant="span" type="bold">
                                    {t('checkout:deliveryMethod:instorePickup')}
                                </Typography>
                                <Typography className="hidden-mobile">
                                    {t('checkout:deliveryMethod:instorePickupDesc')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShippingView;
